import React from 'react'
import './variables.css'
import './global.css'
import Navigation from './navigation'
import { Container, Grid, ThemeProvider } from '@mui/material'
import { AppContextProvider } from '../context/AppContext'
import theme from '../material.theme'
import Background from './Background/Background'
import Social from './Social/Social'
import Footer from './Footer/Footer'

// import 'swiper/css';


class Template extends React.Component {
  render() {
    const { children } = this.props

    return (
      <AppContextProvider>
        <ThemeProvider theme={theme}>
          <div className="site-page">
            <header>
              <Container maxWidth='md' sx={{ marginTop: 4, marginBottom: 2 }}>
                <Grid container justifyContent={'stretch'} spacing={3}>
                  <Grid item flexGrow={1}>
                    <Navigation />
                  </Grid>
                  <Grid item>
                    <Social />
                  </Grid>
                </Grid>
              </Container>
            </header>
            <main>
              {children}
            </main>
            <Footer />
          </div>
          <Background />
        </ThemeProvider>
      </AppContextProvider>
    )
  }
}

export default Template
