import React from 'react';
import ellipse from "../../../static/ellipse.svg";
import ornament from "../../../static/ornament.svg";
import "./Background.css";

const Background: React.FC = () => {
    return (
        <div className="fixed-background">
            <img src={ellipse} width={880} height={880} className="ellipse" />
            <div className='ornaments'>
                <div>
                    <img src={ornament} width={70} height={70} className="ornament" />
                </div>
                <div>
                    <img src={ornament} width={70} height={70} className="ornament" />
                </div>
                <div>
                    <img src={ornament} width={70} height={70} className="ornament" />
                </div>
                <div>
                    <img src={ornament} width={70} height={70} className="ornament" />
                </div>
            </div>
        </div>
    )
}

export default Background