import { Grid, Link, Typography } from '@mui/material'
import React from 'react'

const Author: React.FC = props => {
    return (
        <Grid container spacing={1} sx={{ marginBottom: '60px' }} justifyContent='center'>
            <Grid item>
                <Typography color="#808787" variant="caption">developed by</Typography>
            </Grid>
            <Grid item>
                <Typography color="#808787" variant="caption">
                    <Link href="https://manuko.dev?rel=koktajlove" color='inherit' target={'new'} className="author-link">manuko</Link>
                </Typography>
            </Grid>
        </Grid>
    )
}

export default Author