import { createTheme } from "@mui/material";

const theme = createTheme({
    palette: {
        primary: {
            main: '#F79256'
        },
        secondary: {
            main: '#264040'
        }
    },
    shape: {
        borderRadius: 8
    },
    typography: {
        h2: {
            fontSize: 24,
            fontFamily: 'span'
        },
        h3: {
            fontSize: 20,
            fontFamily: 'span'
        },
        h4: {
            fontSize: 18,
            fontFamily: 'span'
        },
        subtitle1: {
            fontSize: 14,
            fontFamily: 'Inter',
            lineHeight: 1.21
        },
        body1: {
            fontWeight: 500,
            fontFamily: 'Inter',
        },
        button: {
            fontWeight: 600,
            fontFamily: 'Inter',
        }
    }
});

export default theme;