import React from 'react';
import { Grid, Link as LinkMUI } from '@mui/material';
import { Link } from 'gatsby';

function Navigation() {
    return (
        <nav>
            <Grid container spacing={2}>
                <Grid item>
                    <Link to="/">Oferta</Link>
                </Grid>
                <Grid item>
                    <Link to="/o-nas">O nas</Link>
                </Grid>
                <Grid item>
                    <Link to="/kontakt">Kontakt</Link>
                </Grid>
            </Grid>
        </nav>
    )
}
export default Navigation;

export function NavigationFooter() {
    return (
        <>
            <Grid container columnSpacing={2} sx={{ marginTop: 4, fontSize: 12 }}>
                <Grid item>
                    <LinkMUI href="mailto:koktajlove@koktajlove.pl">koktajlove@koktajlove.pl</LinkMUI>
                </Grid>
            </Grid>
            <nav>
                <Grid container columnSpacing={2} sx={{ marginTop: 4, fontSize: 12 }}>
                    <Grid item>
                        <Link to="/rodo">RODO</Link>
                    </Grid>
                    <Grid item>
                        <Link to="/kontakt">Kontakt</Link>
                    </Grid>
                </Grid>
            </nav>
        </>
    )
}
