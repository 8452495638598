import { Container, Grid, Typography } from '@mui/material'
import React from 'react'
import Author from '../Author/Author'
import { NavigationFooter } from '../navigation'
import Social from '../Social/Social'

const Footer: React.FC = props => {
    return (
        <>
            <Container maxWidth="md">
                <Grid container justifyContent={'space-between'} spacing={4} sx={{ marginTop: 12, marginBottom: 16 }}>
                    <Grid item>
                        <FooterInfo />
                    </Grid>
                    <Grid item>
                        <Social />
                    </Grid>
                </Grid>
                <Author />
            </Container>
        </>
    )
}

export default Footer

const FooterInfo: React.FC = props => {
    return (
        <>
            <Typography color='primary' className='font-decorative' sx={{ fontSize: 32, marginBottom: 2 }}>Koktajlove</Typography>
            <Typography sx={{ color: '#FBEFE2' }} variant='body2'>Koktajlove Alicja Deruś</Typography>
            <Typography sx={{ color: '#808787', marginTop: 2, marginBottom: 2 }} variant='body2'>NIP 5842651657</Typography>
            <Typography sx={{ color: '#808787' }} variant='body2'>ul. Smugowa 2D<br />80-299 Gdańsk</Typography>
            <NavigationFooter />
        </>
    );
}