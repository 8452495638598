import { Container, Typography } from '@mui/material'
import React from 'react';
import './Hero.css';

interface HeroProps {
    subtitle: boolean;
    title: string;
}

const Hero: React.FC<HeroProps> = props => {
    const { subtitle, title } = props;

    return (
        <Container maxWidth={'lg'} sx={{ textAlign: 'center' }}>
            <h1 className='hero-title'>{title}</h1>
            {subtitle && <Typography sx={{ fontStyle: 'italic', fontSize: 16, fontWeight: 400 }}>Z miłości do koktajli 🍹</Typography>}
        </Container>
    )
}

export default Hero