import { Grid, Link } from '@mui/material'
import React from 'react';
import PinterestIcon from '@mui/icons-material/Pinterest';
import FacebookRoundedIcon from '@mui/icons-material/FacebookRounded';
import InstagramIcon from '@mui/icons-material/Instagram';

const Social: React.FC = props => {
    return (
        <Grid container columnSpacing={2} alignItems='normal'>
            <Grid item>
                <Decoration />
            </Grid>
            <Grid item>
                <Link href='https://www.facebook.com/Koktajlove-108614108421712' sx={{
                    color: '#FBEFE2',
                    '&:hover': {
                        color: '#FBD1A2'
                    }
                }}><FacebookRoundedIcon color='inherit' /></Link>
            </Grid>
            <Grid item>
                <Decoration />
            </Grid>
            <Grid item>
                <Link href='https://www.instagram.com/koktajlove_pl/' sx={{
                    color: '#FBEFE2',
                    '&:hover': {
                        color: '#FBD1A2'
                    }
                }}><InstagramIcon color='inherit' /></Link>
            </Grid>
            <Grid item>
                <Decoration />
            </Grid>
        </Grid>
    )
}

export default Social;


const Decoration: React.FC = props => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="5.657" height="5.657" viewBox="0 0 5.657 5.657">
            <rect id="Rectangle_75" data-name="Rectangle 75" width="4" height="4" rx="1" transform="translate(2.828) rotate(45)" fill="#f79256" />
        </svg>
    )
}